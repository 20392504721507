import React from "react"
import { graphql } from "gatsby"

import ResourcesListContent from "./resources-list-content"

const ResourcesCategory = props => <ResourcesListContent {...props} />

export const query = graphql`
  query ($categorySlug: String!, $skip: Int!, $limit: Int!, $market: String!) {
    sanityResourceListing(tabs: { content: { market: { eq: $market } } }) {
      tabs {
        content {
          hero
          subtitle
        }
        seo {
          canonical
          description
          metaTitle
          noIndex
          openGraphImage {
            asset {
              url
            }
          }
          removeSitemap
        }
      }
    }
    allSanityResource(
      filter: { tabs: { content: { market: { eq: $market }, category: { slug: { current: { eq: $categorySlug } } } } } }
      limit: $limit
      skip: $skip
      sort: { fields: tabs___content___publishDate, order: DESC }
    ) {
      nodes {
        tabs {
          content {
            title
            category {
              slug {
                current
              }
            }
            slug {
              current
            }
            hero {
              _rawRichText
            }
            market
            _rawFeaturedImage(resolveReferences: { maxDepth: 10 })
          }
          resourceListingPreview {
            logo {
              asset {
                url
                _id
              }
            }
            excerpt
            label {
              title
              color
            }
          }
        }
        _type
      }
    }
    allSanityResourceCategory(filter: { market: { eq: $market } }) {
      nodes {
        id
        title
        slug {
          current
        }
      }
    }
  }
`

export default ResourcesCategory
