import React from "react"
import { Helmet } from "react-helmet-async"

import Seo from "../components/seo/seo"
import Layout from "../components/layout"
import ListingPageContent from "../components/listingPageContent/listingPageContent"
import Breadcrumbs from "../components/breadcrumbs/breadcrumbs"
import { parseMarket } from "../consts/markets"
import { useTranslation } from "../helpers/useTranslation"
import { getHreflang, getPagePath } from "../helpers/getHreflang"

const ResourcesListContent = props => {
  const { t } = useTranslation()
  const { data, pageContext } = props
  const { content, seo } = data?.sanityResourceListing?.tabs
  const categories = data.allSanityResourceCategory
  const items = data.allSanityResource
  const { currentPage, totalPages, market, categorySlug } = pageContext
  const link = market === "en-us" ? "resources" : `${parseMarket(market)}/resources`
  const pagePath = getPagePath()
  const hreflangAbbr = getHreflang()

  return (
    <Layout>
      <Helmet>
        <link rel="alternate" href={pagePath} hreflang={hreflangAbbr} />
        <link
          rel="alternate"
          href={
            market === "es"
              ? pagePath.replace("/es/resources", "/au/resources")
              : market === "en-au"
              ? pagePath.replace("/au/resources", "/es/resources")
              : market === "en-us"
              ? pagePath.replace("/resources", "/au/resources")
              : market === "pt"
              ? pagePath.replace("/pt/resources", "/resources")
              : pagePath
            }
          hreflang={
            market === "es"
              ? "en-au"
              : market === "en-au"
              ? "es"
              : market === "en-us"
              ? "en-au"
              : market === "pt"
              ? "en"
              : "en"
            }
        />
        <link
          rel="alternate"
          href={
            market === "es"
              ? pagePath.replace("/es/resources", "/resources")
              : market === "en-au"
              ? pagePath.replace("/au/resources", "/resources")
              : market === "en-us"
              ? pagePath.replace("/resources", "/es/resources")
              : market === "pt"
              ? pagePath.replace("/pt/resources", "/es/resources")
              : pagePath
            }
          hreflang={
            market === "es"
              ? "en"
              : market === "en-au"
              ? "en"
              : market === "en-us"
              ? "es"
              : market === "pt"
              ? "es"
              : "en"
            }
        />
        <link
          rel="alternate"
          href={
            market === "es"
              ? pagePath.replace("/es/resources", "/pt/resources")
              : market === "en-au"
              ? pagePath.replace("/au/resources", "/pt/resources")
              : market === "en-us"
              ? pagePath.replace("/resources", "/pt/resources")
              : market === "pt"
              ? pagePath.replace("/pt/resources", "/au/resources")
              : pagePath
            }
          hreflang={
            market === "es"
              ? "pt"
              : market === "en-au"
              ? "pt"
              : market === "en-us"
              ? "pt"
              : market === "pt"
              ? "en-au"
              : "en"
            }
        />
        <link
          rel="alternate"
          href={
            market === "es"
              ? pagePath.replace("/es/resources", "/resources")
              : market === "en-au"
              ? pagePath.replace("/au/resources", "/resources")
              : market === "pt"
              ? pagePath.replace("/pt/resources", "/resources")
              : pagePath
          }
          hreflang="x-default"
        />
      </Helmet>
      <Seo
        title={content?.hero}
        breadcrumbs={[
          {
            link,
            title: "Resources",
          },
        ]}
        path={pageContext.pagePath}
        {...seo}
      />
      <Breadcrumbs homePageLabel={t("resources")} steps={[{ label: t("resources") }]} />
      <ListingPageContent
        title={content?.hero}
        subtitle={content?.subtitle}
        items={items}
        entity="resource"
        background="none"
        currentPage={currentPage}
        totalPages={totalPages}
        categorySlug={categorySlug}
        categories={categories}
        baseUrl={link}
      />
    </Layout>
  )
}

export default ResourcesListContent
